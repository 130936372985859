<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <a-btn-refresh @click="$refs.table.updateData()" />
      <a-btn-add :icon="'far fa-plus-square'" :title="'Создать уведомление'" v-if="getAccess('eventCreate')" @click="createNew()" />
    </template>
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="idEdit" :m="m"></edit-dialog>
    </portal>
    <a-table-f-api ref="table" :api="url" :model="m.list" :useQuery="false" :defaults="defaults" @click="onClickRow($event)"> </a-table-f-api>
  </static-fullscreen-card>
</template>

<script>
import { getAccess } from "@/components/mixings";

export default {
  mixins: [getAccess],
  components: {
    editDialog: () => import("./dialogs/changeLogDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      // m1: this.$store.getters["config/get"].models.changeLog.list,
      m: {
        api: "/mechti/change_log/admin",
        list: [
          { name: "id", title: "#", type: "id", sortable: true, width: 50 },
          { name: "createdon", title: "Созадно", type: "datetime", sortable: true, width: 120 },
          { name: "published_on", title: "Опубликовано", type: "datetime", sortable: true, width: 120 },
          { name: "name", title: "Наименование", type: "string", sortable: true, width: 450 },
          { name: "access_all", title: "Общий доступ", type: "switcher", validator1: ["req"], default: 0 },
          { name: "status", title: "Публиковать", type: "select", options: "yesNo", sortable: true, width: 150 },
        ],
        form: [
          { name: "name", title: "Наименование", type: "string", validator: ["req"], autofocus: true },
          { name: "accessRoles", title: "для роли..", type: "selectAccess2", accessType: "role", validator1: ["req"], default: [] },
          { name: "content", title: "Содержимое", type: "ckeditor5", validator: ["req"], rows: -1 },
          { name: "videos", title: "Видео-файлы", type: "filesdatavideo", table_name: "changeLog" },
          { name: "filesRemove", default: [], hidden: true },
          { name: "filesAdd", default: [], hidden: true },
          { name: "access_all", title: "Общий доступ", type: "switcher", default: 0 },
          { name: "status", title: "Публиковать", type: "select", options: "yesNo", default: 0 },
          { name: "data.info", title: "Список инструкций", type: "baseArrayNumber" },
        ],

        config: {
          form: {
            title: "Change Log",
            fields: "name,accessRoles,content,videos,filesRemove,filesAdd,status,access_all,data.info",
            fieldsRO: "",
            fieldsForm: "name,status#6,access_all#6,data.info,accessRoles,content,videos",
          },
        },
      },
      defaults: {
        sort: { key: "id", order: "DESC" },
        paramName: "changeLog",
      },
    };
  },
  created() {
    this.$root.title = "История изменений системы";
  },
  computed: {
    url() {
      return this.m.api;
    },
    permit() {
      return this.getAccess("menu.changeLogAdmin");
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
